import React from 'react'

import { FaChevronRight, FaChevronDown, FaCircle } from 'react-icons/fa'

function Frontend() {
    let iconstylegreen = { color: "green" }
    let iconstyleyellow = { color: "yellow" }
    let iconstylered = { color: "green" }


    return (
        <>
            <div className=" max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full ">
                <div className=''>
                    <div className='overflow-y-scroll max-h-[400px] p-5 rounded-lg bg-slate-200'>
                  

                        <a href="https://github.com/kbax6342/blog-post-sanity" target="_blank" className='mt-5'>
                            <div className='bg-[#0a192f] rounded max-w-[600px} h-auto p-6 drop-shadow-xl outline hover:outline-green-500'>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='text-white uppercase font-bold text-xl mb-2 tracking-wide'>Blog Post Site - Sanity.io</h3>
                                    <FaCircle style={iconstylegreen} />
                                </div>
                                <div className='flex flex-col sm:flex-row text-white justify-between'>
                                <p>Website showing blogs created with CMS system (Sanity.io) </p>
                                </div>
                            </div>
                        </a>

                        <a href="https://github.com/kbax6342/react-chat-app" target="blank">
                            <div className='bg-[#0a192f] rounded max-w-[600px} h-auto p-6 drop-shadow-xl outline  mt-4 hover:outline-green-500'>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='text-white uppercase font-bold text-xl mb-2'>React Chat App</h3>
                                    <FaCircle style={iconstyleyellow} />

                                </div>

                                <div className='flex flex-col sm:flex-row text-white justify-between'>
                                    <p> A chat app using React Js Framework</p>
                                </div>

                            </div>
                        </a>

                        
                        <a href="https://github.com/kbax6342/react-todo-list" target="blank">
                            <div className='bg-[#0a192f] rounded max-w-[600px} h-auto p-6 drop-shadow-xl outline  mt-4 hover:outline-green-500'>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='text-white uppercase font-bold text-xl mb-2'>React TODO List</h3>
                                    <FaCircle style={iconstylegreen} />

                                </div>

                                <div className='flex flex-col sm:flex-row text-white justify-between'>
                                    <p> An Application to create TODO list in React Js</p>
                                </div>

                            </div>
                        </a>
                        
                        <a href="https://github.com/kbax6342/React-Travel-Website" target="blank">
                            <div className='bg-[#0a192f] rounded max-w-[600px} h-auto p-6 drop-shadow-xl outline hover:outline-green-500 mt-4 '>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='text-white uppercase font-bold text-xl mb-2'>React Travel Website</h3>
                                    <FaCircle style={iconstyleyellow} />

                                </div>

                                <div className='flex flex-col sm:flex-row text-white justify-between'>
                                    <p>Website Showcasing travel places using React Framework </p>
                                </div>

                            </div>

                        </a>
                        <a href="https://github.com/kbax6342/github-finder-reactapp" target="blank">
                            <div className='bg-[#0a192f] rounded max-w-[600px} h-auto p-6 drop-shadow-xl outline hover:outline-green-500 mt-4 '>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='text-white uppercase font-bold text-xl mb-2'>Github Finder </h3>
                                    <FaCircle style={iconstylegreen} />

                                </div>

                                <div className='flex flex-col sm:flex-row text-white justify-between'>
                                    <p>Application search github account using React Framework</p>
                                </div>

                            </div>
                        </a>
                      

                    </div>
                </div>


            </div>
        </>
    )

}

export default Frontend