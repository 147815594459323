import React, { useState, useEffect } from 'react'
import logo from '../assets/BA-Tech-Small.png'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {FaBars , FaTimes, FaGithub, FaLinkedin, FaGoogleDrive } from 'react-icons/fa'
// import {Link} from 'react-scroll'
import { Link, useLocation } from 'react-router-dom'


const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    const location = useLocation()

    useEffect(()=> {
      if (location.hash) {
          let elem = document.getElementById(location.hash.slice(1))
          if (elem) {
              elem.scrollIntoView({behavior: "smooth"})
          }
      } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
      }
}, [location,])

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
       <div>
           <img src={logo} alt="Logo Image" style={{width: '50px'}} />
       </div>
       
       {/* menu */}
           <ul className='hidden md:flex'>
               <li>
               <Link to="/portfolio" smooth={true} duration={500} >
               Home
               </Link>
               </li>
               <li>
               <Link to="#about" smooth={true} duration={500} >
               About
               </Link>  
                </li>
               <li>
               <Link to="#skills" smooth={true} duration={500} >
                Skills
               </Link>  
               </li>
               <li>
                 <Link to="#work" smooth={true} duration={500} >
                Work
                 </Link>  
             </li>
             <li>
                 <Link to={`/services`} smooth={true} duration={500} >
                Services
                 </Link>  
             </li>
               <li>
               <Link to="#contact" smooth={true} duration={500} >
               Contact
                 </Link>   
                </li>
           </ul>
      

        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars /> : <FaTimes />}
        </div>

         {/* Mobile menu */}
  
            <ul id="mobile-menu" className={!nav ? 'hidden' :  'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center z-9'}>

                <li className='py-6 text-4xl' onClick={() => setNav(false)} > <Link to="/" smooth={true} duration={500} >
               Home
               </Link></li>
                <li className='py-6 text-4xl' onClick={() => setNav(false)}> <Link to="/#about" smooth={true} duration={500} >
               About
               </Link>  </li>
                <li className='py-6 text-4xl' onClick={() => setNav(false)}> <Link to="/#skills" smooth={true} duration={500} >
               Skills
               </Link> </li>
                <li className='py-6 text-4xl' onClick={() => setNav(false)}> <Link to="/#work" smooth={true} duration={500} >
                Work
                 </Link> </li>
                 <li className='py-6 text-4xl' onClick={() => setNav(false)}>
                 <Link className='py-6 text-4xl' to={`/services`} smooth={true} duration={500} >
                Services
                 </Link>  
             </li>
             <li className='py-6 text-4xl' onClick={() => setNav(false)}>
                 <Link className='py-6 text-4xl' to={`/experience`} smooth={true} duration={500} >
                Services
                 </Link>  
             </li>
                <li className='py-6 text-4xl' onClick={() => setNav(false)}>  <Link to="/#contact" smooth={true} duration={500} >
               Contact
                 </Link> </li>
            </ul>
       

          {/* Social Icons */}
         
          <div className='hidden lg:flex  fixed flex-col top-[35%] left-0 mt-5'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="https://drive.google.com/drive/folders/1IuERHK-1RV1tbAGOdhLDc3aKtvn3dyJp?usp=sharing" target="_blank">
                        Portfolio <FaGoogleDrive size={30}/>
                    </a>
                </li>
                <li  className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                     href="https://www.linkedin.com/in/kevin-nelms-baxter/" target="_blank">
                        LinkedIn <FaLinkedin  size={30}/>
                    </a>
                </li>
                <li  className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                     href="https://github.com/kbax6342" target="_blank">
                        Github <FaGithub  size={30}/>
                    </a>
                </li>
              
            </ul>
          </div>

    </div>
  )
}

export default Navbar