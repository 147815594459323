
import {HiArrowNarrowRight} from 'react-icons/hi'

import { Link} from 'react-router-dom'

function Intro() {
  return (

    <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full '>
    <p className='remove-fill text-green-600 animate__animated animate__fadeIn first'>Hi, my name is</p>
    <h1 className='remove-fill text-4xl sm:text-7xl font-bold text-[#ccd6f6] animate__animated animate__backInLeft second'>Kevin Baxter</h1>
    <h2 className='remove-fill text-4xl sm:text-7xl font-bold text-[#8892b0] animate__animated animate__backInRight third'>I'm a Full Stack Developer.
    </h2>
    <p className='remove-fill text-[#8892b0] py-4 max-w-[700px] animate__animated animate__fadeIn fourth'>I'm a full-stack developer specializing in building and designing exceptional digital experiences. Currently, I'm focused on building responsive full-stack web applications.
    </p>


<div className='remove-fill'>
    <Link to="#about" smooth={true} duration={500} >
    <button className='remove-fill text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-green-600 hover:border-green-600 animate__animated animate__fadeIn fifth'>
    View 
    <span className='group-hover:route-90 duration-300'>
    <HiArrowNarrowRight className='ml-3 '/>
    </span> 
    </button>
    </Link>  
</div>

</div>
  )
}

export default Intro