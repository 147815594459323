import React from 'react'
import {HiArrowNarrowRight, HiPaperAirplane} from 'react-icons/hi'
import 'animate.css'
import { BrowserRouter as Router, Link   } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Intro from '../components/Intro'
import About from "../components/About"
import Skills from '../components/Skills'
import Projects from '../components/Projects'
import Work from '../components/Work'
import moon from "../assets/moon.png"
import logo from '../assets/BA-Tech-Small.png'


import Portfolio from './Portfolio'
import { useEffect } from 'react'





const Home = () => {


  return (
    <>

  <div style={{backgroundImage: `url(https://i.ibb.co/xSjkPft/Untitled-design-4.png)` }} className="h-screen bg-cover  flex-col justify-center align-center">

    <div className='w-full h-full flex flex-col justify-center'>
      <h1 className='text-center text-3xl md:text-4xl mb-3 space-font text-white bg-slate-400 py-3 '>Welcome to the
      
      <span className='md:text-5xl ml-3 text-[#0a192f]'>B</span>
      <span className='md:text-5xl mr-3 text-[#197b30]'>A</span>
       Tech </h1>
    <img src={moon} className="mx-auto animate-spin-slow max-w-[65%] lg:max-w-[35%]"></img>

      <Link to="/portfolio" ><h1 className='text-3xl text-center mt-5 bg-[#197b30] text-white rounded md:w-[30%] p-5 shadow-sm mx-auto'>Enter Here</h1></Link>
   
    </div>
 
  
  </div>
    </>
    
  )
}

export default Home