import React from 'react'

import senior from '../assets/senior.png'
import royalFlush from '../assets/royal-flush.jpg'
import bookme from '../assets/book-me.jpg'
import fitness from '../assets/fitnessf-app.jpg'
import starbucks from '../assets/starbucks.png'
import genesis2 from '../assets/genesis-treatment.png'


function Work() {
    return (
        <div id="work" name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-green-600'>Work</p>
                    <p className='py-6'>//Check out some of my recent work</p>
                </div>

                <div className='grid sm:grid-cols-2 md:grid-col-3 gap-4' >
                    {/* <div style={{ backgroundImage: `url(${royalFlush})` }} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                        {/* {Hover Effects} */}
                        {/* <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                                Node Js Application
                            </span>
                            <div className='pt-8 text-center'>
                                <a href="https://www.royalflushinvegas.com/" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Demo
                                    </button>
                                </a>
                                <a href="https://github.com/kbax6342/las-vegas-live" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Github
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div> */} 
                    <div style={{ backgroundImage: `url(${senior})` }} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                        {/* {Hover Effects} */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                                React Application
                            </span>
                            <div className='pt-8 text-center'>
                                <a href="https://senior-discounts.vercel.app/" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Demo
                                    </button>
                                </a>
                                <a href='https://github.com/kbax6342' target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Github
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundImage: `url(${bookme})` }} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                        {/* {Hover Effects} */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                              React Application
                            </span>
                            <div className='pt-8 text-center'>
                                <a href="https://book-me-coral.vercel.app/" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Demo
                                    </button>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundImage: `url(${fitness})` }} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                        {/* {Hover Effects} */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider'>
                              React Application
                            </span>
                            <div className='pt-8 text-center'>
                                <a href="https://react-gym-workout.vercel.app/" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Demo
                                    </button>
                                </a>
                                <a href='https://github.com/kbax6342/React-Gym-Workout' target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                       Github
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                     <div style={{ backgroundImage: `url(${starbucks})` }} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                        {/* {Hover Effects} */}
                         <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider text-center'>
                              React Application 
                            </span>
                            <br></br>
                            <span className='text-2xl font-bold text-white tracking-wider text-center'>
                             Starbucks Replica
                            </span>
                            <div className='pt-8 text-center'>
                                <a href="https://starbucks-replica.vercel.app/" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Demo
                                    </button>
                                </a>
                                <a href='https://github.com/kbax6342/starbucks-replica'>
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                       Github
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>  
                    <div style={{ backgroundImage: `url(${genesis2})` }} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                        {/* {Hover Effects} */}
                        <div className='opacity-0 group-hover:opacity-100'>
                            <span className='text-2xl font-bold text-white tracking-wider text-center'>
                              This is a sample React Application 
                            </span>
                            <br></br>
                           
                            <div className='pt-8 text-center'>
                                <a href="https://www.genesistrc.com/" target="_blank">
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                        Demo
                                    </button>
                                </a>
                                <a href='https://github.com/kbax6342/genesis-treatment'>
                                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                                       Github
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                  
                    </div>
                </div>



            </div>
       
    )
}

export default Work