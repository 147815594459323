import React, { useRef, useState } from 'react'
import ReactImg from '../assets/react.png'
import csharp from '../assets/CSharp.png'
import { FaChevronRight, FaChevronDown, FaCircle } from 'react-icons/fa'


import Front from './Frontend'
import Sharp from './CSharp'



function Projects() {
    let iconstylegreen = { color: "green" }
    let iconstyleyellow = { color: "yellow" }
    let iconstylered = { color: "green" }


    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);





    return (
        <div id="projects" name="projects" className='w-full h-auto bg-[#0a192f] text-gray-300' >

            <div className=" max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full ">
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-green-600 '>Projects</p>
                    <p className='py-4'>//These are the personal projects I have completed</p>
                </div>

                <button onClick={() => setShow(!show)} className='shadow-md shadow[#040c16] bg-white  rounded hover:scale-110 duration-500 min-w-[70%] m-auto p-5'>

                    <div className='flex flex-row items-center justify-between'>
                        <p className="text-black font-bold  uppercase lg:text-2xl">React Js</p>
                        <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                        <p className='text-black font-bold mr-3'>View Projects</p>
                        <FaChevronRight size={20} className="place-self-end self-center text-black font-bold" />
                        <FaChevronDown size={20} className="place-self-end self-center text-black font-bold hidden" />
                    </div>
                </button>
                {
                    show ? <Front /> : null
                }
                <button onClick={() => setShow2(!show2)} className='shadow-md shadow[#040c16] bg-white  rounded hover:scale-110 duration-500 min-w-[70%] m-auto p-5 mt-6'>

                    <div className='flex flex-row items-center justify-between'>
                        <p className="text-black font-bold  uppercase lg:text-2xl">C# &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                        <img className='w-20 mx-auto' src={csharp} alt="HTML icon" />
                        <p className='text-black font-bold mr-3'>View Projects</p>
                        <FaChevronRight size={20} className="place-self-end self-center text-black font-bold" />
                        <FaChevronDown size={20} className="place-self-end self-center text-black font-bold hidden" />
                    </div>
                </button>
                {
                    show2 ? <Sharp /> : null
                }
                <img src="https://ghchart.rshah.org/2016rshah" alt="2016rshah's Github chart"  className='mt-5'/>
            </div>

        </div>
    )
}

export default Projects;