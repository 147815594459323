import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';

import Home from './pages/Home'
import Portfolio from './pages/Portfolio';
import Services from './pages/Services'



function App() {
  return (
    <div>
    
     <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/portfolio' element={<Portfolio/>} />
     </Routes>
 
   
    </div>
  );
}

export default App;
