import {React, useState, StyleSheet, useRef} from 'react'

import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import JavaScript from '../assets/javascript.png'
import ReactImg from '../assets/react.png'
import Node from '../assets/node.png'
import Firebase from '../assets/firebase.png'
import AWS from '../assets/aws.png'
import GitHub from '../assets/github.png'
import Tailwind from '../assets/tailwind.png'
import Mongo from '../assets/mongo.png'
import Wordpress from '../assets/wordpress.png'
import Jquery from '../assets/jquery.png'
import CSharp from '../assets/CSharp.png'



import { FaChevronRight } from 'react-icons/fa'




import {
    BrowserRouter,

    Link

} from "react-router-dom";
import { isVisible } from '@testing-library/user-event/dist/utils'


function Skills() {

   


    function displayProject() {
       
    
    }

    
    const hidden = useRef(null)

    const removeHidden = (e) => {
        hidden.current.classList.remove('hidden');
    }

    const addHidden = (e) => {
        hidden.current.classList.add('hidden')
    }
    return (

        <div id="skills" name='skills' className='w-full h-auto bg-[#0a192f] text-gray-300'>
            {/* {Container} */}
            <div className='max-w-[1000px] m-auto p-4 flex flex-col justify-center w-full h-full '>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-green-600 '>Skills</p>
                    <p className='py-4'>//These are the technologies I've worked with</p>
                </div>


                <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                        <p className='my-4'>HTML</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                        <p className='my-4'>CSS</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={JavaScript} alt="HTML icon" />
                        <p className='my-4'>JAVASCRIPT</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                        <p className='my-4'>REACT</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={GitHub} alt="HTML icon" />
                        <p className='my-4'>GITHUB</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={Node} alt="HTML icon" />
                        <p className='my-4'>NODE JS</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={Mongo} alt="HTML icon" />
                        <p className='my-4'>MONGO DB</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={Firebase} alt="HTML icon" />
                        <p className='my-4'>FIREBASE</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={Wordpress} alt="HTML icon" />
                        <p className='my-4'>WORDPRESS</p>
                    </div>
                    <div className='shadow-md shadow[#040c16] hover:scale-110 duration-500'>
                        <img className='w-20 mx-auto' src={Jquery} alt="HTML icon" />
                        <p className='my-4'>JQUERY</p>
                    </div>
                    <button onClick={removeHidden} className='shadow-md shadow[#040c16] hover:scale-110 duration-500'  >
                        <img className='w-20 mx-auto' src={CSharp} alt="HTML icon" />
                        <div  className='flex flex-row items-center justify-center mt-3'>
                         
                            <p>C#</p>
                          
                        </div>
                    </button>
                 
            </div>
        </div>
       
    </div>
    )
}

export default Skills