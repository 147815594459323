import React from 'react'
import { FaCircle } from 'react-icons/fa'


export default function CSharp() {

    let iconstylegreen = { color: "green" }
    return (
        <>
            <div className=" max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full ">
                <div className=''>
                    <div className='overflow-y-scroll max-h-[400px] p-5 rounded-lg bg-slate-200'>


                        <a href="https://github.com/kbax6342/Xamarin-Mobile-App" target="_blank" className='mt-5'>
                            <div className='bg-[#0a192f] rounded max-w-[600px} h-auto p-6 drop-shadow-xl outline hover:outline-green-500'>
                                <div className='flex flex-row justify-between'>
                                    <h3 className='text-white uppercase font-bold text-xl mb-2 tracking-wide'>Xamarin Mobile App - Knots Calculator</h3>
                                    <FaCircle style={iconstylegreen} />
                                </div>
                                <div className='flex flex-col sm:flex-row text-white justify-between'>
                                    <p>Mobile App for Windows and Android Converting Knots to Miles </p>
                                </div>
                            </div>
                        </a>
                        



                    </div>
                </div>


            </div>
        </>
    )
}
