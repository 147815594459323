import React from 'react'

const About = () => {
  return (
    
    <div id="about" name='about' className='w-full h-auto bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
            <div className='sm:text-right pb-8 pl-4'>
                <p className='text-4xl font-bold inline border-b-4 border-green-600'>
                    About
                </p>
            </div>
            <div></div>
        </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='text-4xl font-bold '>
                <p>Hi. I'm Kevin, nice to meet you. Please take a look around</p>
            </div>
            <div>
                <p>
                I am a US Dept. of Labor Certified Software Developer with 4 years of experience developing, testing and implementing code through training and independent study. After spending a year with General Electric as a front-end developer, I decided to take that experience with development and design and apply it to my career in becoming an Web Developer for companies like CDK Global, Artisan Digital Media, and Ulliance Inc. Some languages that I have gained a firm grasp on in my career are as follows: Adobe Suite, HTML(5), CSS(3), JavaScript, JQuery, Node JS, and SQL. With these tools, I have gained a strong footing in the web development field and plan on expanding my tools with each opportunity.   
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default About