import React from 'react'
import Navbar from '../components/Navbar'
import Intro from '../components/Intro'
import About from "../components/About"
import Skills from '../components/Skills'
import Projects from '../components/Projects'
import Work from '../components/Work'
import Contact from "../components/Contact"

const Portfolio = () => {
  return (
   <>
  <div className=' md:block w-full h-screen bg-[#0a192f]'>
            <Navbar />
            <Intro/>
            <About />
            <Skills />
            <Projects />
            <Work />
            <Contact />
        </div>
   </>
  )
}

export default Portfolio
