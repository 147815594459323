import React, { useRef, useState } from 'react'
import OctoImg from '../assets/1.png'
// import GlobalImg from './assets/2.png'
import MetalImg from '../assets/3.png'
import BrainImg from '../assets/4.png'
import HeadImg from '../assets/5.png'

import Contact from '../components/Contact'
import Navbar from '../components/Navbar'



function Services() {




   return (
   
      <div name="services" className='w-full h-auto bg-[#0a192f] text-gray-300'>
            <Navbar />
         <div className=" max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full ">
            <div className='mt-[200px]'>
               <p className='text-4xl font-bold inline border-b-4 border-green-600  '>Services</p>
               <p className='py-4'>//These are the personal projects I have completed</p>
            </div>

            <div className=" flex  h-max bg-[#0a192f] w-80 transition duration-500 hover:scale-110 my-3 flex justify-center items-center mx-auto min-w-[75%] rounded">
               <img src={OctoImg} alt="octogon" className='max-w-xs hidden md:block' />
               <div className='flex flex-col flex-1 bg-neutral-300 h-full  p-4 rounded'>
                  <h1 className='text-black text-3xl text-center uppercase pb-5'>Website Development</h1>
                  <p className='text-black max-w-[90%] mx-auto'>Your company's website is like a resume for your business. The website needs to say who you are, what you do, explain what makes your better than your competition, and compel a potential client to "hire" you. Our completely customer website design services are tailored to your organization from the ground up with the purpose of generating business growth.</p>
               </div>
            </div>

            <div className=" flex h-max bg-[#0a192f] w-80 transition duration-500 hover:scale-110 my-3 flex justify-center items-center mx-auto min-w-[75%] rounded">
               <img src={MetalImg} alt="octogon" className='max-w-xs hidden md:block ' />
               <div className='flex flex-col flex-1 bg-neutral-300 h-full  p-4 rounded'>
                  <h1 className='text-black text-3xl text-center uppercase pb-5'>Reputation Management</h1>
                  <p className='text-black max-w-[90%] mx-auto'>BA Tech provides a service that gets your customers spreading the good word about your business on the sites and platforms that matter most. Our solution helps you tactfully ask and remind customers for feedback, guides them through the online review process, and gives you a chance to reach dissatisfied customers before they write a negative review. Your responsibility is to provide great customer service. We will handle the rest.
                  </p>
               </div>
            </div>

            <div className=" flex h-max bg-[#0a192f] w-80 transition duration-500 hover:scale-110 my-3 flex justify-center items-center mx-auto min-w-[75%] rounded">
               <img src={HeadImg} alt="octogon" className='max-w-xs hidden md:block ' />
               <div className='flex flex-col flex-1 bg-neutral-300 h-full  p-4 rounded'>
                  <h1 className='text-black text-3xl text-center uppercase pb-5'>Content Creation</h1>
                  <p className='text-black max-w-[90%] mx-auto'>The production of engaging, practical, and informative content is paramount to any brand, especially on the web. Success is not an accident. BA Tech offers a wide array of content creation services tailored to meet small business needs.  Our goal is to take this task off your plate so you can stick to doing what you do best. Our most popular content creation services can be broken down into three main categories.
                  </p>
               </div>
            </div>

            <div className=" flex  h-max bg-[#0a192f] w-80 transition duration-500 hover:scale-110 my-3 flex justify-center items-center mx-auto min-w-[75%] rounded">
               <img src={BrainImg} alt="octogon" className='max-w-xs hidden md:block ' />
               <div className='flex flex-col flex-1 bg-neutral-300 h-full  p-4 rounded'>
                  <h1 className='text-black text-3xl text-center uppercase pb-5'>Social Media Marketing</h1>
                  <p className='text-black max-w-[90%] mx-auto'>
                     Social media is not just sharing photos and inspirational quotes. It is a way for businesses to engage directly with their customers, advertise to specific demographics, and a means to attract new clients.As your full-service social media agency partner, BA Tech brings you the latest social media trends, strategies, and winning advice directly to your organization. You will never have to worry about having the time, attention, or resources to ensure you are not missing out on this lucrative market.
                  </p>
               </div>
            </div>

            <Contact />



         </div>


      </div>
   )
}

export default Services;